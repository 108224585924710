/**
 * DEPENDENCIES:
 * - A publicly shared Google Sheet: https://docs.google.com/spreadsheets/d/e/2PACX-1vTuTogkwE2lIlZOAgzW6Rmygyj7x2s0tMfSXqWrGOrzDPEeMIMJoZpz9_qY65rC4Mhs6BnLxqZpR9Xj/pubhtml
 * - public-google-sheets-parser from https://github.com/fureweb-com/public-google-sheets-parser
 */
var traverse = require('traverse');
//var _ = require('lodash');

const PUBLIC_GOOGLE_SHEET_ID = '1jOSwd1h6fxt3yYoupVR0Fsq7fDt6h_G0i86eJb4gaRo'
const PUBLIC_GOOGLE_SHEET_XLSX = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTuTogkwE2lIlZOAgzW6Rmygyj7x2s0tMfSXqWrGOrzDPEeMIMJoZpz9_qY65rC4Mhs6BnLxqZpR9Xj/pub?output=xlsx'

const parser = new PublicGoogleSheetsParser()

let source_data = {}
let tree_data = {}
let any_tree
let chart
let chartSVG 
let panZoomChart = {}
let chart_path = []
let programs_num
let itemId = 0
let drillDepth
let drillTargetParent_L1
let drillTargetParent_L2

let sectors = {
    sheet: 'list_sectors',
    name: 'Emissions target sector',
    dimension_name: 'emissions_target_sector',
    list: []
}

let stakeholders = {
    sheet: 'list_stakeholders',
    name: 'Priority stakeholder',
    dimension_name: 'priority_stakeholder_1',
    list: []
}

let actions = {
    sheet: 'list_actions',
    name: 'Primary action',
    dimension_name: 'primary_action',
    list: []
}

let interventions = {
    sheet: 'list_levers',
    name: 'Policy lever',
    dimension_name: 'government_intervention_type',
    list: []
}

let dimensions = [
    sectors,
    stakeholders,
    actions,
    interventions
]

//let preloader = anychart.ui.preloader();

let selected_dimensions = [0, 1]
let select_1 = document.getElementById('dimension_1')
let select_2 = document.getElementById('dimension_2')
let submit_btn = document.getElementById('submit_btn')

/* let interact_btn = document.getElementById('b_interact') */
let zin_btn = document.getElementById('b_zin')
let zout_btn = document.getElementById('b_zout')
let reset_btn = document.getElementById('b_reset')
//let drill_up_btn = document.getElementById('b_drill_up')
let drill_top_btn = document.getElementById('b_drill_top')

let pan_up_btn = document.getElementById('b_pan_up')
let pan_left_btn = document.getElementById('b_pan_left')
let pan_right_btn = document.getElementById('b_pan_right')
let pan_down_btn = document.getElementById('b_pan_down')

let instructions = document.getElementById('instructions_bg')
let glossary = document.getElementById('glossary_bg')

let instructions_btn = document.getElementById('tab_instructions')
let glossary_btn = document.getElementById('tab_glossary')
let glossary_tips = document.querySelectorAll('.glossary_tip')


let loader = document.getElementById('loading_bg')


glossary_tips.forEach(function(item){
    item.addEventListener("click", (event) => {
        event.preventDefault()
        instructions.classList.add('hide')
        glossary.classList.remove('hide')
        
    })
})

instructions.addEventListener("click", (event) => {
    instructions.classList.add('hide')
})

glossary.addEventListener("click", (event) => {
    glossary.classList.add('hide')
    
})

instructions_btn.addEventListener("click", (event) => {
    event.preventDefault()
    glossary.classList.add('hide')
    instructions.classList.remove('hide')

})

glossary_btn.addEventListener("click", (event) => {
    event.preventDefault()
    instructions.classList.add('hide')
    glossary.classList.remove('hide')
})

select_1.addEventListener("change", (event) => {
    filterOptions({input: select_2, value: event.target.value})
    selected_dimensions[0] = Number(event.target.value)
})

select_2.addEventListener("change", (event) => {
    filterOptions({input: select_1, value: event.target.value})
    selected_dimensions[1] = Number(event.target.value)
})

submit_btn.addEventListener("click", (event) => {
    event.preventDefault()
    toggleLoading()

    //preloader.visible(true);
    //document.querySelector('main').classList.add('fadeOut')
    //document.getElementById('info').classList.add('hide')
    

    setTimeout(function(){
        tree_data = {}
        chart = {}
        drillTargetParent_L1 = 0
        drillTargetParent_L2 = 0
        drillTargetDepth = 0
        itemId = 0

        document.getElementById('chart').innerHTML = ''
        //document.getElementById('info').innerHTML = ''
        
        transformData()
    }, 2000)
    
})

function isDesktop(){
    //console.log('is desktop?')
    //console.log(window.innerWidth)
    return window.innerWidth >= 1024 ? true : false;
  }


function toggleModals(){
    //instructions.classList.add('hide')
    //glossary.classList.add('hide')
}



function setChartControls(){

    
    enterZoomMode()
    let stepX = 50
    let stepY = 50

    let pan_up_btn = document.getElementById('b_pan_up')
    let pan_left_btn = document.getElementById('b_pan_left')
    let pan_right_btn = document.getElementById('b_pan_right')
    let pan_down_btn = document.getElementById('b_pan_down')

    pan_up_btn.addEventListener("click", (event) => {
        event.preventDefault()
        //panZoomChart.svgPanZoom.panBy({x: 0, y: -(stepY)})
        panZoomChart.svgPanZoom.panBy({x: 0, y: stepY})
    })

    pan_left_btn.addEventListener("click", (event) => {
        event.preventDefault()
        //panZoomChart.svgPanZoom.panBy({x: -(stepX), y: 0})
        panZoomChart.svgPanZoom.panBy({x: stepX, y: 0})
    })

    pan_right_btn.addEventListener("click", (event) => {
        event.preventDefault()
        //panZoomChart.svgPanZoom.panBy({x: stepX, y: 0})
        panZoomChart.svgPanZoom.panBy({x: -(stepX), y: 0})
    })

    pan_down_btn.addEventListener("click", (event) => {
        event.preventDefault()
        //panZoomChart.svgPanZoom.panBy({x: 0, y: stepY})
        panZoomChart.svgPanZoom.panBy({x: 0, y: -(stepY)})
    })
    
    zin_btn.addEventListener("click", (event) => {
        event.preventDefault()
        enterZoomMode()
        panZoomChart.svgPanZoom.zoomIn()
    })
    
    zout_btn.addEventListener("click", (event) => {
        event.preventDefault()
        enterZoomMode()
        panZoomChart.svgPanZoom.zoomOut()  
    })
    
    reset_btn.addEventListener("click", (event) => {
        event.preventDefault()

        
        panZoomChart.svgPanZoom.resetZoom()
        panZoomChart.svgPanZoom.center()
    })
/*
    drill_up_btn.addEventListener("click", (event) => {
        event.preventDefault()

        //
        var path = chart.getDrilldownPath();
        //console.log(path)
        var parent

        if (path.length == 3){
            parent = path[1].node.ka.name
        }

        if (path.length == 2){
            parent = path[0].node.ka.name
        }
        
        console.log(any_tree.search("name", parent))

        var item = any_tree.search("name", parent);
        
        chart.drillTo(item);
        //
        if ( drillDepth == 1){
            let root = dimensions[selected_dimensions[0]].name
            let item = any_tree.search("name", root);
            chart.drillTo(item);

        }
        
        if (drillDepth == 2){
            let item = any_tree.search("name", drillTargetParent_L2 );
            console.log(item)
            chart.drillTo(item)
            drillDepth --
        }
        
        
        //drillDepth --
        
        
        
    })
    */
    

    drill_top_btn.addEventListener("click", (event) => {
        event.preventDefault()
        
        let root = dimensions[selected_dimensions[0]].name
       
        let item = any_tree.search("name", root);
        // drill down to the item
        chart.drillTo(item);
              
    })
}

function filterOptions({input, value}){ 
    //console.log(input, value)

    let options = input.querySelectorAll('option')
    let option = options[Number(value)]

    options.forEach(function(option){
        if (option == options[Number(value)] ){
            option.selected = false
            option.disabled = true
        } else {
            option.disabled = false
        }
    })
}

window.addEventListener('resize', function(){
    location.reload()
})

init()

function init(){
    // create a preloader
    
    // render preloader to the DOM
    //preloader.render();
    //preloader.visible(true);

    
    //document.getElementById('chart').classList.add('fadeOut')
    //document.getElementById('info').classList.add('hide')


    
    if ( isDesktop()){
        filterOptions({input: select_1, value: select_2.value})
        filterOptions({input: select_2, value: select_1.value})
        loadData()
    }
    
}


function toggleLoading(){
    loader.classList.toggle('hide')
}

async function loadData(){
    try {
        /** Parser default gets primary sheet */
        source_data = await parser.parse(PUBLIC_GOOGLE_SHEET_ID).then((items) =>{ return items })

        programs_num = source_data.length
        
        /** Parser requires explicit requests for non-primary sheets */
        sectors.list = await parser.parse(PUBLIC_GOOGLE_SHEET_ID, sectors.sheet).then((items) =>{ return amendListValues(items) })

        stakeholders.list = await parser.parse(PUBLIC_GOOGLE_SHEET_ID, stakeholders.sheet).then((items) =>{ return amendListValues(items) })

        actions.list = await parser.parse(PUBLIC_GOOGLE_SHEET_ID, actions.sheet).then((items) =>{ return amendListValues(items) })

        interventions.list = await parser.parse(PUBLIC_GOOGLE_SHEET_ID, interventions.sheet).then((items) =>{ return amendListValues(items) })
    } catch(error){
        console.log(error)
    }
    //console.log(source_data)
    transformData()
}

/** Fix list values on pass-through
 * Parser script expects Sheet Row 1 to be header row, our list sheets don't have header row */
function amendListValues(items){
    let output = []
    items.forEach(function(item){
        // Didn't name the sheet in cell A1, so our key is named value 'None'
        output.push(item.None)
    })
    // Have to re-insert valid value of 'None' to list
    output.unshift('None')
    return output
}


function createTreeFromDimensions({dimensions=[]}){
    function createBrandFromDimensions(dimension){}
}


function transformData(){

    // TODO: simplify and refactor
    // AnyChart.js expects hierarchical format for sunburst chart rendering  { name: $name, children: [{ name: $name, children: [...]}]}
    let d_1 = dimensions[selected_dimensions[0]]
    let d_2 = dimensions[selected_dimensions[1]]

    // create root node
    let primary = {name: d_1.name, itemId: String(itemId), children:[]} //, value: 9800}
    // for each value in 1st dimension
    d_1.list.forEach(function(value){

        itemId ++
        
        // filter the source data
        let data_L1 = getBranches({
            branchData: source_data,
            branchName: d_1.dimension_name,
            branchLabel: d_1.name,
            branchValue: value,
            itemId
        })
        //console.log('data_L1 length', data_L1.data) 
        // only add child if there's segment data
        if (data_L1.results !== 'none'){
            //data_L1.value = 1225
            primary.children.push(data_L1)
        }
    })



    // for each branch in root
    primary.children.forEach(function(secondary){
        
        // for each value in 2nd dimension
        d_2.list.forEach(function(value){
            itemId ++

            // filter the source data
            let data_L2 = getBranches({
                branchData: secondary.data,
                branchName: d_2.dimension_name,
                branchLabel: d_2.name,
                branchValue: value,
                itemId
            })
            //console.log('data_L2:', data_L2) 
            // only add child if there's segment data
            if (data_L2.results !== 'none'){
                //data_L2.value = 225
                secondary.children.push(data_L2)
            }
        })
        
        /*
        // for each subbranch in branch
        secondary.children.forEach(function(tertiary){
    
            // for each value in 3rd dimension
            actions.list.forEach(function(action){
                let data_L3 = getBranches({
                    branchData: tertiary.data,
                    branchName: actions.dimension_name,
                    branchLabel: action.name,
                    branchValue: action
                })
                // only add child if there's segment data
                if (data_L3.results !== 'none'){
                    //data_L3.value = 25
                    tertiary.children.push(data_L3)
                }
            })
    
            // for each sub-subbranch in subbranch
            tertiary.children.forEach(function(quartary){
                // for each value in 4th dimension
        
                interventions.list.forEach(function(intervention){
                    let data_L4 = getBranches({
                        branchData: quartary.data,
                        branchName: interventions.dimension_name,
                        branchLabel: intervention.name,
                        branchValue: intervention
                    })
                    // only add child if there's segment data
                    if (data_L4.results !== 'none'){
                        //data_L4.value = 5
                        quartary.children.push(data_L4)
                    }
                })
            
                // for each leaf in sub-subbranch
                quartary.children.forEach(function(record){
                    /*
                    if (record.data.length == 0 ){
                        delete record.data
                        delete record.children
                    }
                    
                    //console.log(record)
                })
                loops[2] ++

            })
            loops[1] ++

        })
        */
    })

    
    tree_data = {...primary}
    // AnyChart expects root array for data 
    tree_data = [tree_data]

   //console.log(tree_data)

    iterateNested(tree_data)

    makeChart({filter_1: d_1.name, filter_2: d_2.name})
}

function hideInfo(){
   // console.log('called')
    //document.getElementById('info').classList.add('hide')
    //document.getElementById('info').innerHTML = ''

    let info = document.getElementById('info')
    info.innerHTML = ''
        let d3 = document.createElement('h2')
            d3.innerText = 'Program information';

            let d4 = document.createElement('p')
          d4.innerText = 'Select a program from the outer ring to view detailed information.'

            info.append(d3, d4)

    
}

function showInfo({
        index,
        dimension,
        name,
        program,
        workstream,
        description,
        multiple_stakeholders_check,
        multiple_stakeholders,
        other_stakeholders_check,
        other_stakeholders,
        net_zero_check,
        net_zero_plan_priorities_list,
        innovation_check,
        innovation,
        priority_stakeholder_check,
        priority_stakeholder_list
    }){


        console.log(other_stakeholders_check,
            other_stakeholders)
    
    if ( dimension == 'Program' ){
        let info = document.getElementById('info')
        info.innerHTML = ''

        let a = document.createElement('h4')
        a.innerText = dimension;
        info.append(a)

        let b = document.createElement('h2')
        b.innerText = program;
        info.append(b)
    
        let c = document.createElement('h3')
        
        if (workstream){
            c.innerText = 'Workstream: ' + workstream;
            info.append(c)
        }
    
        let d = document.createElement('div')
        d.innerText = description;
        info.append(d)

        if (multiple_stakeholders_check !== undefined && multiple_stakeholders_check !== 'None'){
            let d1 = document.createElement('h4')
            d1.innerText = 'Priority stakeholders';

            let d2 = document.createElement('p')
        
            d2.innerText = multiple_stakeholders.replaceAll('\n* None', '')

            info.append(d1, d2)
        }

        /*
        if (priority_stakeholder_check !== undefined && multiple_stakeholders_check === 'None'){
            let d1 = document.createElement('h4')
            d1.innerText = 'Priority stakeholder';

            let d2 = document.createElement('p')
        
            d2.innerText = priority_stakeholder_list.replaceAll('\n* None', '')

            info.append(d1, d2)
        }
        */

        if ( other_stakeholders_check !== 'None' && other_stakeholders_check !== undefined){
            let e = document.createElement('h4')
            e.innerText = 'Other stakeholders';

            let f = document.createElement('p')
            f.innerText = other_stakeholders.replaceAll('\n* None', '')

            info.append(e, f)

        }

        

        if ( net_zero_check !== 'None' && net_zero_check !== undefined){
            //console.log(net_zero_check, net_zero_plan_priorities_list)
            let d3 = document.createElement('h4')
            d3.innerText = 'Net zero plan priorities';

            let d4 = document.createElement('p')
            d4.innerText = net_zero_plan_priorities_list.replaceAll('\n* None', '')

            info.append(d3, d4)

        }

        if ( innovation_check !== 'None'  && innovation_check !== undefined){
            /*
            let d5 = document.createElement('h4')
            d5.innerText = 'Innovation phase(s)';

            let d6 = document.createElement('p')
            d6.innerText = innovation.replaceAll('\n* None', '')

            info.append(d5, d6)
            */

        }

       
            
        

        

        
    }

    

    document.getElementById('info').classList.remove('hide')
}

function makeChart({filter_1, filter_2}){
    if ( filter_1 == filter_2 ){
        alert('Can\'t apply duplicate filters.')
        selected_dimensions = [0, 1]
        //chart.clear()
        transformData()
    }

    //anychart.theme(anychart.themes.light_blue);

    // create a chart and set the data
    // AnyChart expects root array for data 
    //let treeData = [tree_data]
    // Unsure if this validation against schema is required
    any_tree = anychart.data.tree(tree_data, "as-tree");

    //console.log(any_tree)

    function getChild(index){
        return any_tree.getChildAt(index)
    }

    // hook data up to chart
    chart = anychart.sunburst(any_tree, "as-tree");
    chart.calculationMode("parent-independent");

    var tooltip = chart.tooltip();
    tooltip.title().text("{%dimension}: {%name} L2");
    tooltip.format("{%dimension}: {%name}");

    tooltip.fontColor("#fff");
    tooltip.fontSize(16);
    tooltip.fontWeight(400);
    tooltip.fontFamily('Public Sans')
    tooltip.background().fill("black");
    tooltip.width(300);
    tooltip.padding(8);


    var palette = anychart.palettes.distinctColors();
    palette.items(['#a8edb3','#8cdbe5', '#8ce0ff', '#cebfff', '#f4b5e6', '#ffb8c1', '#ffce99', '#fde79a', '#e8d0b5']);
    chart.palette(palette)

    chart.hovered({fill: '#ffffff'});
    chart.selected().fill("#efefef", 1);

    
    chart.fill(function () {
        if (this.level == 0)
            //return anychart.color.darken(this.mainColor, 0.25);
        if (this.level == 1)
            return anychart.color.darken(this.mainColor, 1);
        if (this.level == 2)
            return anychart.color.lighten(this.mainColor, 0.25);
        if (this.level == 3)
            return anychart.color.lighten(this.mainColor, 0);

        return anychart.color.darken(this.mainColor, 0.1);
    });  

    chart.listen("drillChange", function(e){
        //var path = chart.getDrilldownPath();
        //console.log(e.current)
        //console.log('Drilled to ' + e.current.get('name'))
        leaveZoomMode()
        enterZoomMode()

        drillDepth = e.current.node.Ai.depth
        console.log(drillDepth)

        if (drillDepth == 1){
            drillTargetParent_L1 = e.current.node.Ai.pathFromRoot[0].ka.name
            
        } 
        
        if (drillDepth == 2){
            drillTargetParent_L2 = e.current.node.Ai.pathFromRoot[1].ka.name
        }
        

        
        
        
    })

    chart.listen("pointClick", function (e) {
        let index
        try {
            index = e.point.getIndex()
            
        } catch(error){
            //console.log(error)
            index = null
        }

        
        
        //console.log(index)
        if (index ){
            let dimension = e.point.get("dimension")

            if (dimension && dimension == 'Program'){
                let name = e.point.get("name")
                let program = e.point.get("program")
                let workstream = e.point.get("workstream")
                let description = e.point.get("description")
                let multiple_stakeholders_check = e.point.get("multiple_stakeholders_1")
                let multiple_stakeholders = e.point.get("multiple_priority_stakeholder_list")
                
                let other_stakeholders_check = e.point.get("other_stakeholder_1")
                let other_stakeholders = e.point.get("other_stakeholders_list")

                let net_zero_check = e.point.get("net_zero_plan_priorities_1")
                let net_zero_plan_priorities_list = e.point.get("net_zero_plan_priorities_list") 
                
                let innovation_check = e.point.get("innovation_phase_1");
                let innovation = e.point.get("innovation_phase_list")



                let priority_stakeholder_check = e.point.get("priority_stakeholder_1")
                let priority_stakeholder_list = e.point.get("priority_stakeholder_list")
                    
                showInfo({
                    index,
                    dimension,
                    name,
                    program,
                    workstream,
                    description,
                    multiple_stakeholders_check,
                    multiple_stakeholders,
                    other_stakeholders_check,
                    other_stakeholders,
                    net_zero_check,
                    net_zero_plan_priorities_list,
                    innovation_check,
                    innovation,
                    priority_stakeholder_check,
                    priority_stakeholder_list
                })
             
            } else {
                hideInfo()
            }
        } else {
            hideInfo() 
        }
    });
            
    //chart.interactivity().selectionMode("none");
    


    chart.labels().format("{%name}");
    
    /*
    chart.contextMenu().itemsProvider(function(items) {
        //delete items["about"]
      
        return items;
    });
    */
  

    chart.contextMenu(true);

    chart.contextMenu().itemsFormatter(function(items){
        index = items['save-chart-as'].index;

        // Disable save as image option
       delete items["save-chart-as"].subMenu["save-chart-as-pdf"];


       items["save-chart-as"].subMenu["save-chart-as-png"].action = function() {
        chart.saveAsPng({
            "quality": 1,
            "filename": "NSW-Gov_Net-Zero-Map_" + Date.now()
        });
      };
       
      items["save-chart-as"].subMenu["save-chart-as-jpg"].action = function() {
        chart.saveAsJpg({
            "quality": 1,
            "filename": "NSW-Gov_Net-Zero-Map_" + Date.now()
        });
      };
       
       items['download-source-data'] = {
        'text': 'Download source data (*.xlsx)',
        'href': PUBLIC_GOOGLE_SHEET_XLSX,
        'index': index + 0.02
        };

       delete items["save-data-as"]
       
       // delete about and separator
       delete items["exporting-separator"];
       delete items["share-with"];
       delete items["about"];
       delete items["full-screen-enter"];
       delete items["full-screen-separator"];

       //console.log(items)

       items["save-chart-as"].text = "Export chart as image...";
       items["print-chart"].text = "Print chart";

       
       
      // return modified array
      return items;
    });

    // set donut hole
    chart.innerRadius(60);
    // Hide root node donut
    chart.level(0).enabled(false);
    
    /*
    // Set donut label
    var label = anychart.standalones.label();
    //let label_txt = `${filter_1}\n + \n${filter_2}`
    let label_txt = ``

    label.text(label_txt);
    label.width("100%");
    label.height("100%");
    label.fontColor("#333");
    label.fontSize(14);
    label.fontWeight(600);
    label.hAlign("center");
    label.vAlign("middle");
    label.fontFamily('Public Sans')


    // set the center content
    chart.center().content(label);
    */

    chart.leaves().labels().position("circular");

    chart.labels().fontColor('#000')
    .fontFamily('Arial')
    .fontSize(10)
    .fontStyle('500')
    .useHtml(false)

    chart.background().fill('#fff 0')

 

       
    // set the container id
    chart.container("chart");

    chart.listen("chartDraw",function() {
        // get the drilldown path and convert it to a string
        getPath(chart.getDrilldownPath());
        
        if (chart_path.length == 1 ){
            drill_top_btn.setAttribute('disabled', true)
            //drill_up_btn.setAttribute('disabled', true)
        }
        if (chart_path.length >= 2 ) {
            drill_top_btn.removeAttribute('disabled')
            //drill_up_btn.removeAttribute('disabled')
        }
        
      });

      chart.a11y().titleFormat(function(e){
        return `${programs_num} NSW Government net zero programs displayed by ${dimensions[selected_dimensions[0]].name} and ${dimensions[selected_dimensions[1]].name} dimensions in a sunburst diagram chart`
      });

    // draw the chart
    chart.draw();

    
    
    //preloader.visible(false);
    toggleLoading()

    /*
    let chartSVG = document.querySelector('#chart > div > svg > g > g > g:last-child')
    console.log(chartSVG)
    appendSVGChild('circle', chartSVG, attributes={id:'donut_hole', r:100, fill: 'red'})
    */

    document.querySelector('main').classList.remove('fadeOut')

    chartSVG = document.querySelector('#chart > div > svg')
    
    
    setChartControls()

    
}

function toggleDrillControls(){

}

function getPath(path) {
    /* go through the array of data items
    and use the get() method to obtain the "name" field */
    chart_path = []
    for (i = 0; i <  path.length; i++){ 
        chart_path.push(path[i].get("name"))
    }
    
  }

function iterateNested(obj) {
    traverse(obj).forEach(function (x){
        // Find branches in tree with key children length == 0
        if (this.key == 'children'){
            if (x.length == 0){
                // get path to node and modify to get sibling
                let siblingPath = [ ...this.path ]
                siblingPath[siblingPath.length - 1] = 'data'
                // get sibling data
                let data = traverse(obj).get(siblingPath)
                //console.log(data)

                 // Assign sibling key data values to children
                traverse(obj).set(this.path, data)
                
            }
            
        }

        /*
        if (this.key == 'description'){
            traverse(obj).set(this.path, '')
        }
        if (this.key == 'priority_stakeholder_list'){
            traverse(obj).set(this.path, '')
        }

        if (this.key == 'net_zero_meri_outcome_list'){
            traverse(obj).set(this.path, '')
        }

        if (this.key == 'net_zero_plan_priorities_list'){
            traverse(obj).set(this.path, '')
        }

        if (this.key == 'innovation_phase_list'){
            traverse(obj).set(this.path, '')
        }

        if (this.key == 'other_stakeholders_list'){
            traverse(obj).set(this.path, '')
        }
        */
    });
}


function getBranches({branchData, branchName, branchLabel, branchValue, itemId}){
    
    let segment =  branchData.filter(function(seg) {
        return seg[branchName] == branchValue
    });

    if ( segment.length > 0){
        segment.forEach(function(item){
            // flatten workstream subcategory into programs category
            if (item.program && item.workstream ){
                // Required property for AnyChart.js
                item.name = item.program + ' - ' + item.workstream
            } else {
                // Required property for AnyChart.js
                item.name = item.program
            }
            // Optional property for AnyChart.js for calculating portions
            item.dimension = "Program"
            item.value = '1'
        })

        return {name: branchValue, dimension: branchLabel, children: [], data: segment, itemId: String(itemId)}
        
    } else {
        return {results: 'none'}
    }
}

function appendSVGChild(elementType, target, attributes = {}, text = '') {
    const element = document.createElementNS('http://www.w3.org/2000/svg', elementType);
    Object.entries(attributes).map(a => element.setAttribute(a[0],a[1]));
    if (text) {
      const textNode = document.createTextNode(text);
      element.appendChild(textNode);
    }
    target.prepend(element);
    return element;
  };

function enterZoomMode(){
    panZoomChart.svgPanZoom = svgPanZoom(chartSVG, {
        dblClickZoomEnabled: false,
        mouseWheelZoomEnabled: false,
        zoomScaleSensitivity: 0.5,
        minZoom: 1,
        maxZoom: 15 })
}

function leaveZoomMode(){
    //panZoomChart.svgPanZoom.disablePan();
    //panZoomChart.svgPanZoom.disableZoom();

    panZoomChart.svgPanZoom.destroy(); 
    delete panZoomChart.svgPanZoom;
}

function getGoogleData() {
    let range = `Womens_Doubles!A1:J51`;
    let title = "National Women's Doubles Top 50";

    gapi.client
      .init({
        apiKey: "AIzaSyA7uMyb_qqGThNhlB_8yfeW5h7_wi-QDig",
        discoveryDocs: [
          "https://sheets.googleapis.com/$discovery/rest?version=v4",
        ],
      })
      .then(() => {
        return gapi.client.sheets.spreadsheets.values.get({
          spreadsheetId: "1T20pe7D5SYS6LqwV9ksTL7h52l57ePvaN5mF4ocemR8",
          range: range, // for example: List 1!A1:B6
        });
      })
      .then((response) => {
        renderLeaderboard(response.result.values, title);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }




